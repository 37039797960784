import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // return true;

    if (this.authService.isAuthenticated()) {
      console.log('authenticated');
      // this.router.navigate(['dashboard']);
      return true;
    } else {
      console.log('not authenticated');
      localStorage.removeItem('token');
      this.router.navigate(['/auth/login']);
      return false;
    }
  }
}
