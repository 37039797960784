import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PaymentsService } from '../../../services';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'list-payments',
  templateUrl: './list-payments.component.html',
  styleUrls: ['./list-payments.component.scss'],
})
export class ListPaymentsComponent implements OnInit {
  @ViewChild('showDialog', null) showDialog: TemplateRef<any>;
  @ViewChild('deleteDialog', null) deleteDialogRef: TemplateRef<any>;
  @ViewChild('editDialog', null) editDialogRef: TemplateRef<any>;

  public gender: string;
  public loadingSpinner = false;
  public source: any;
  public payments: any;
  public page = 1;
  public pageSize = 10;
  public status: string ;
  public locationType: string;
  public selectedId: any;
  public payment: any = {
    nanny: '',
  };
  constructor(
    private readonly dialogService: NbDialogService,
    private readonly paymentsService: PaymentsService,
    private toastrService: NbToastrService,
    private readonly router: Router,

  ) {
    this.loadAllPayments();
  }

  ngOnInit() {
  }

  loadAllPayments() {
    this.loadingSpinner = true;
    this.paymentsService.getPayments().subscribe((payments: any) => {
    this.payments = payments ;
    console.log('this.payments', this.payments);

      this.loadingSpinner = false;

      for (let i = 0; i < this.payments.length; i++) {
        if (this.payments[i].status === 'CANCELED') {
          this.status = 'Famille a annulée le paiement';
        } else if (this.payments[i].status === 'PROCESSING') {
          this.status = 'paiement en cours';
        }  else if (this.payments[i].status === 'SUCCEEDED') {
          this.status = 'Paiement réussi';
        }

        if (this.payments[i].reservation.locationType === 'AT_FAMILY') {
          this.locationType = 'Chez la famille';
        } else if (this.payments[i].reservation.locationType === 'AT_NANNY') {
          this.locationType = 'Chez la nannie';
        } else if (this.payments[i].reservation.locationType === 'OTHER') {
          this.locationType = 'Autre adresse';
        }
      }


    }
    , error => {
      this.loadingSpinner = false;
      if (error.status === 403) {
        localStorage.clear();
        this.router.navigate(['auth/login']);
      }

    });
  }

  onDelete(payment: any): void {
    const creatorUser = payment.family.name;
    this.dialogService.open(this.deleteDialogRef, {
      context: { id: payment.id, creatorUser },
    });
  }

  delete(id: number) {
    this.loadingSpinner = true;
    this.paymentsService.delete(id).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('Supprimer', 'payment supprimé avec succés.', 'success');
        this.loadAllPayments();
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Supprimer', 'Erreur survenue lors de suppression de payment.', 'danger');
      },
    );
  }

  onActionShowClick(payment: any) {
    this.openDialog(this.showDialog, payment);
  }

  openDialog(dialog: any, payment: any) {
    const context: any = {
      payment,
    };
    this.dialogService.open(dialog, { context });
  }

  showToast(title: string, message: string, status: any) {
    this.toastrService.show(message, title, { status });
  }

  onCustom(payment: any) {
    this.selectedId = payment.id;
    console.log('id', this.selectedId);
    this.router.navigate(['/reservations/show-payment/', payment.id]);
  }

}
