import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LOGIN_URL, CHANGE_PASSWORD_URL , RESET_PASSWORD_URL} from '../../assets/common/urls';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor( private http: HttpClient) { }

  login(user) {
   // return this.http.post<any>(LOGIN_URL, user);
    return this.http.post<any>(environment.apiUrl + LOGIN_URL, user);

  }

  logout() {
    localStorage.clear();
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      return true;
    }  else {
      return false;
    }
  }


  getToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  setToken() {
    return localStorage.getItem('accessToken');
  }

  changePassword(body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: 'bearer ' + localStorage.getItem('token'),
    });
    return this.http.post(CHANGE_PASSWORD_URL, body, { headers });
  }

  restPassword(email: string) {
    const headers = new HttpHeaders({
      'device-type': 'web',
    });
    return this.http.get(RESET_PASSWORD_URL + email, { headers });
  }
}
