import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './modules/app-routing.module';
import { NbImplementationModule } from './modules/nb-implementation.module';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { MainComponent } from './pages/main/main.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { EditUserComponent } from './pages/users/edit-user/edit-user.component';
import { AddUserComponent } from './pages/users/add-user/add-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ListNanniesComponent } from './pages/nannies/list-nannies/list-nannies.component';
import { NbThemeModule, NbLayoutModule, NbListModule } from '@nebular/theme';
import { NgxPaginationModule } from 'ngx-pagination';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RequestPasswordComponent } from './pages/auth/request-password/request-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ListFamiliesComponent } from './pages/families/list-families/list-families.component';
import { ListEnterprisesComponent } from './pages/enterprises/list-enterprises/list-enterprises.component';
import { ListPaymentsComponent } from './pages/payments/list-payments/list-payments.component';
import { ListReservationsComponent } from './pages/reservations/list-reservations/list-reservations.component';
import { ShowReservationComponent } from './pages/reservations/show-reservation/show-reservation.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    NotFoundComponent,
    UsersListComponent,
    EditUserComponent,
    AddUserComponent,
    ListNanniesComponent,
    RequestPasswordComponent,
    DashboardComponent,
    ListFamiliesComponent,
    ListEnterprisesComponent,
    ListPaymentsComponent,
    ListReservationsComponent,
    ShowReservationComponent,
    SubscriptionsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NbImplementationModule,
    NgxPaginationModule,
    NbListModule,
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    CoreModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbImplementationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

