import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {

  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }),
  };

  getAssets(id: any) {
    // return this.http.get(environment.apiUrl + 'api/user-assets/' + id , this.httpOptions);
    return this.http.get(environment.apiUrl + 'api/user-assets/' + id, this.httpOptions);

  }

}
