import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[]  = [

  {
    title: 'Entreprises',
    icon: 'enterprise',
    link: '/list-enterprises',
  },
  {
    title: 'Familles',
    icon: 'family',
    link: '/list-families',
  },
  {
    title: 'Nannies',
    icon: 'nanny',
    link: '/list-nannies',
  },
  {
    title: 'Reservations',
    icon: 'reservation',
    link: '/list-reservations',
  },
  {
    title: 'Paiements',
    icon: 'payment',
    link: '/list-payments',
  },
  {
    title: 'Abonnements',
    icon: 'payment-2',
    link: '/subscriptions',
  },
];
