import { Component, OnInit } from '@angular/core';
import { NbSidebarService, NbMenuService } from '@nebular/theme';
import { MENU_ITEMS } from './pages-menu';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  menu = MENU_ITEMS;
  constructor(
    private readonly router: Router,
    private sidebarService: NbSidebarService,
    protected menuService: NbMenuService,

    ) {
  }

  ngOnInit() {
    this.menuService.onItemSelect().subscribe((event: {tag: string, item: any}) => { this.sidebarService.collapse('menu-sidebar'); });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }


  logout() {
    localStorage.clear();
    this.router.navigate(['auth/login']);
  }
}
