import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PAYMENTS_URL } from '../../assets/common/urls';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {

  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }),
  };

  getPayments() {
    return this.http.get(environment.apiUrl + 'api/all-payments', this.httpOptions);
  }

  delete(id: number) {
    return this.http.put(environment.apiUrl + PAYMENTS_URL + '/remove/' + id, null, this.httpOptions);
  }

  getPaymentById(id: string) {
    return this.http.get(environment.apiUrl + 'api/payment' +  '/' + id , this.httpOptions);
  }

}
