import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SubscriptionsService } from '../../services/subscriptions.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
  @ViewChild('showDialog', null) showDialog: TemplateRef<any>;
  @ViewChild('deleteDialog', null) deleteDialogRef: TemplateRef<any>;
  @ViewChild('editDialog', null) editDialogRef: TemplateRef<any>;
  @ViewChild('activateDialog', null) activateDialogRef: TemplateRef<any>;

  public kind: string;
  public loadingSpinner = false;
  public source: any;
  public loading = false;
  public page = 1;
  public pageSize = 10;
  public subscriptions: any;
  public status: string ;
  public userType: string;

  constructor(
    private readonly dialogService: NbDialogService,
    private readonly subscriptionsService: SubscriptionsService,
    private readonly router: Router,
    private toastrService: NbToastrService,

  ) {
    this.loadAllSubscriptions();
  }

  ngOnInit() {
  }

  loadAllSubscriptions() {
    this.loadingSpinner = true;
    this.subscriptionsService.getSubscriptions().subscribe((subscriptions: any) => {
    this.subscriptions = subscriptions ;
    console.log('this.subscriptions', this.subscriptions);

      this.loadingSpinner = false;

      for (let i = 0; i < this.subscriptions.length; i++) {
        if (this.subscriptions[i].payment.status === 'PROCESSING') {
          this.status = 'paiement en cours';
        }  else if (this.subscriptions[i].payment.status === 'SUCCEEDED') {
          this.status = 'Paiement réussi';
        }

           if (this.subscriptions[i].creatorUser.userType === 'FAMILY') {
          this.userType = 'Famille';
        }  else if (this.subscriptions[i].creatorUser.userType === 'NANNY') {
          this.userType = 'Nannie';
        }
      }


    }
    , error => {
      this.loadingSpinner = false;
      if (error.status === 403) {
        localStorage.clear();
        this.router.navigate(['auth/login']);
      }

    });
  }



  onActionShowClick(payment: any) {
    this.openDialog(this.showDialog, payment);
  }

  openDialog(dialog: any, payment: any) {
    const context: any = {
      payment,
    };
    this.dialogService.open(dialog, { context });
  }

  showToast(title: string, message: string, status: any) {
    this.toastrService.show(message, title, { status });
  }


}
