// export const BASE_URL = 'https://nanny-non-stop.continuousnet.com/';
 export const BASE_URL = 'https://api.nannynonstop.com/';
export const LOGIN_URL = 'auth/login';
export const USERS_URL = 'api/users';
export const CHANGE_PASSWORD_URL = 'change-password/';
export const RESET_PASSWORD_URL = 'auth/reset/';
export const NANNIES_URL = 'api/nannies';
export const ENTERPRISES_URL = 'api/enterprises';
export const FAMILIES_URL = 'api/families';
export const RESERVATIONS_URL = 'api/reservations';
export const PAYMENTS_URL = 'api/payments';
export const SUBSCRIPTIONS_URL = 'api/subscriptions';
export const ASSETS_URL = 'api/assets';
