import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NANNIES_URL, USERS_URL } from '../../assets/common/urls';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NanniesService {

  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }),
  };

  getNannies() {
    return this.http.get(environment.apiUrl + NANNIES_URL, this.httpOptions);
  }

  delete(id: number) {
    return this.http.put(environment.apiUrl + NANNIES_URL + '/remove/' + id, null, this.httpOptions);
  }

  edit(id: number, data: any) {
    return this.http.put(environment.apiUrl + USERS_URL + '/' + id, data, this.httpOptions);
  }

  activateUser(data: any) {
    return this.http.post(environment.apiUrl + USERS_URL + '/activate' , data, this.httpOptions);
  }

  publishUser(data: any) {
    return this.http.post(environment.apiUrl + NANNIES_URL + '/activate' , data, this.httpOptions);
  }

  inactivateUser(data: any) {
    return this.http.post(environment.apiUrl + USERS_URL + '/desactivate' , data, this.httpOptions);
  }

  inpublishUser(data: any) {
    return this.http.post(environment.apiUrl + NANNIES_URL + '/desactivate' , data, this.httpOptions);
  }

}
