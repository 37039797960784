import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EnterprisesService } from '../../../services/enterprises.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list',
  templateUrl: './list-enterprises.component.html',
  styleUrls: ['./list-enterprises.component.scss'],
})

export class ListEnterprisesComponent implements OnInit {
  @ViewChild('showDialog', null) showDialog: TemplateRef<any>;
  @ViewChild('deleteDialog', null) deleteDialogRef: TemplateRef<any>;
  @ViewChild('editDialog', null) editDialogRef: TemplateRef<any>;
  @ViewChild('activateDialog', null) activateDialogRef: TemplateRef<any>;

  enterprises: any = [];
  public type: string;
  public source: any;
  public loadingSpinner = false;
  public page = 1;
  public pageSize = 10;

  constructor(
    private readonly dialogService: NbDialogService,
    private readonly enterprisesService: EnterprisesService,
    private toastrService: NbToastrService,
    private readonly router: Router,
  ) {
    this.loadAllEnterprises();
  }

  ngOnInit() {
  }

  loadAllEnterprises() {
    this.loadingSpinner = true;
    this.enterprisesService.getEnterprises().subscribe(
      (enterprises: any) => {
        const enterprisesType = enterprises.filter(data => data.creatorUser.userType === 'ENTERPRISE');
        this.source = enterprisesType.map(enterprise => {
          return {
            id: enterprise.id,
            companyName: enterprise.companyName,
            companyRegistrationNumber: enterprise.companyRegistrationNumber,
            creatorUserId: enterprise.creatorUser.id,
            creatorUserEmail: enterprise.creatorUser.email,
            creatorUserUsername: enterprise.creatorUser.name,
            picture: enterprise.creatorUser.picture,
            phone: enterprise.creatorUser.phone,
            createdAt: enterprise.creatorUser.createdAt,
            language: enterprise.creatorUser.language,
            gender: enterprise.creatorUser.gender,
            birthDate: enterprise.creatorUser.birthDate,
            address: enterprise.creatorUser.address,
            zipCode: enterprise.creatorUser.zipCode,
            cityName: enterprise.creatorUser.cityName,
            type: enterprise.creatorUser.type,
            enabled: enterprise.creatorUser.enableOauth,

          };

        });

        for (let i = 0; i < enterprisesType.length; i++) {
          if (enterprisesType[i].type === 'MICRO_NURSERY') {
            this.type = 'Micro crèche';
          } else if (enterprisesType[i].type === 'EVENT_AGENCY') {
            this.type = 'Agence évènementielle';
          } else if (enterprisesType[i].type === 'CONCIERGE') {
            this.type = 'Conciergerie';
          } else if (enterprisesType[i].type === 'OTHER_TYPE') {
            this.type = 'Autre';
          }
        }
        // console.log('source', this.source);
        for (let i = 0; i < this.source.length; i++) {
          if (this.source[i].picture == null) {
            this.source[i].picture = 'assets/images/no-image.png';
          } else {
            this.source[i].picture = environment.apiUrl + this.source[i].picture;
          }
        }
        this.loadingSpinner = false;
      }, error => {
        this.loadingSpinner = false;
        if (error.status === 403) {
          localStorage.clear();
          this.router.navigate(['auth/login']);
        }

      });


  }

  onActionShowClick(enterprise: any) {
    console.log('contxt', enterprise);
    this.openDialog(this.showDialog, enterprise);

  }

  openDialog(dialog: any, enterprise: any) {
    const context: any = {
      enterprise,
    };
    this.dialogService.open(dialog, { context });
  }

  onDelete(enterprise: any): void {
    const creatorUserUsername = enterprise.creatorUserUsername;
    this.dialogService.open(this.deleteDialogRef, {
      context: {
        id: enterprise.id,
        creatorUserUsername,
        creatorUserId: enterprise.creatorUserId,
      },
    });
  }

  delete(id: number) {
    console.log('User id to delete', id);

    this.loadingSpinner = true;
    this.enterprisesService.delete(id).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('Supprimer', 'Enterprise supprimé avec succés.', 'success');
        this.loadAllEnterprises();
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Supprimer', 'Erreur survenue lors de suppression de l\'enterprise.', 'danger');
      },
    );
  }

  showToast(title: string, message: string, status: any) {
    this.toastrService.show(message, title, { status });
  }

  onEdit(enterprise: any) {
    console.log('id', enterprise.id);
    this.dialogService.open(this.editDialogRef, {
      context: { id: enterprise.id, enterprise },
    });
  }

  edit(id: number, data: any) {
    console.log('data', data);

    const body = {
      companyName: data.enterprise.companyName,
      email: data.enterprise.creatorUserEmail,
      phone: data.enterprise.phone,
      address: data.enterprise.address,
    };

    this.loadingSpinner = true;
    this.enterprisesService.edit(data.enterprise.creatorUserId, body).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('modification', 'enterprise modifié avec succés.', 'success');
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Supprimer', 'Erreur survenue lors de modification .', 'danger');
      },
    );
  }

  onActionActivateClick(enterprise: any): void {
    const email = enterprise.creatorUserEmail;
    this.dialogService.open(this.activateDialogRef, {
      context: { id: enterprise.id, email },
    });
  }

  activate(data: any) {
    console.log('data', data);

    this.loadingSpinner = true;
    this.enterprisesService.activateUser(data).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('Activation', 'Compte activé avec succés.', 'success');
        this.loadAllEnterprises();
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Activation', 'Erreur survenue lors de l activation  de compte.', 'danger');
      },
    );
  }

}
