import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RESERVATIONS_URL } from '../../assets/common/urls';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReservationsService {

  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }),
  };

  getReservations() {
    return this.http.get(environment.apiUrl + RESERVATIONS_URL, this.httpOptions);
  }

  delete(id: number) {
    return this.http.put(environment.apiUrl + RESERVATIONS_URL + '/remove/' + id, null, this.httpOptions);
  }

  edit(id: number, data: any) {
    return this.http.put(environment.apiUrl + RESERVATIONS_URL + '/' + id, data, this.httpOptions);
  }

  getReservationById(id: string) {
    return this.http.get(environment.apiUrl + RESERVATIONS_URL +  '/' + id , this.httpOptions);
  }

}
