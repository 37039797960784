import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReservationsService } from '../../../services/reservations.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'show-reservation',
  templateUrl: './show-reservation.component.html',
  styleUrls: ['./show-reservation.component.scss'],
})
export class ShowReservationComponent implements OnInit {
  public kids: any;
  public leaf: any;
  public selectedId: any;
  public loadingSpinner = false;
  public page = 1;
  public pageSize = 10;
  public gender: string;
  public status: string;
  public reservation: any = {
  };
  public periods: any;

  constructor(
    public reservationsService: ReservationsService,
    private router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,

  ) { }

  ngOnInit() {
    this.loadingSpinner = true;

    this.route.params.subscribe(params => {
      this.reservationsService.getReservationById(params.id).subscribe((reservation: any) => {
        this.reservation = reservation;
        console.log('this.reservation', this.reservation);
        this.loadingSpinner = false;

          if (this.reservation.family.creatorUser.picture == null) {
            this.reservation.family.creatorUser.picture = 'assets/images/users.svg';
          } else {
            this.reservation.family.creatorUser.picture = environment.apiUrl + this.reservation.family.creatorUser.picture;
          }
          if (this.reservation.nanny.creatorUser.picture == null) {
            this.reservation.nanny.creatorUser.picture = 'assets/images/users.svg';
          } else {
            this.reservation.nanny.creatorUser.picture = environment.apiUrl + this.reservation.nanny.creatorUser.picture;
          }
          const periods = this.reservation.periods;
          if (periods != null) {
            for (let i = 0; i < periods.length; i++) {
              console.log('getReservation() - reservation: ' + JSON.stringify(periods[i]));
              this.periods = periods;
            }
          }

        /*  if (this.reservation.status === 'FAMILY_CANCEL') {
            this.status = 'Famille a annulée la réservation';
          } else if (this.reservation.status === 'FAMILY_DELETE') {
            this.status = 'Famille a supprimée la réservation';
          } else if (this.reservation.status === 'FAMILY_REQUEST') {
            this.status = 'Nannie N a pas encore répondu';
          } else if (this.reservation.status === 'NANNY_ACCEPTED') {
            this.status = 'Nannie a acceptée';
          } else if (this.reservation.status === 'NANNY_CANCEL') {
            this.status = 'Nannie a annulée la réservation';
          } else if (this.reservation.status === 'NANNY_REFUSED') {
            this.status = 'Nannie a refusée';
          } else if (this.reservation.status === 'PAYMENT_SUCCESS') {
            this.status = 'Paiement réussi';
          } else if (this.reservation.status === 'PAYMENT_NANNY_SUCCESS ') {
            this.status = 'Paiement réussi';
          }*/
      }
      , error => {
        this.loadingSpinner = false;
        if (error.status === 403) {
          localStorage.clear();
          this.router.navigate(['auth/login']);
        }
      });
    });
  }


  back() {
    this.router.navigateByUrl('/list-reservations');
  }

}
