import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FamiliesService } from '../../../services/families.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list',
  templateUrl: './list-families.component.html',
  styleUrls: ['./list-families.component.scss'],
})

export class ListFamiliesComponent implements OnInit {
  @ViewChild('showDialog', null) showDialog: TemplateRef<any>;
  @ViewChild('deleteDialog', null) deleteDialogRef: TemplateRef<any>;
  @ViewChild('editDialog', null) editDialogRef: TemplateRef<any>;
  @ViewChild('activateDialog', null) activateDialogRef: TemplateRef<any>;

  public kind: string;
  families: any = [];
  loadingSpinner = false;
  public source: any;
  public loading = false;
  public page = 1;
  public pageSize = 10;

  constructor(
    private readonly dialogService: NbDialogService,
    private readonly familiesService: FamiliesService,
    private readonly router: Router,
    private toastrService: NbToastrService,

  ) {
    this.loadAllFamilies();
  }

  ngOnInit() {
  }

  loadAllFamilies() {
    this.loadingSpinner = true;
    this.familiesService.getFamilies().subscribe((families: any) => {
      const familiesType = families.filter(familie => familie.creatorUser.userType === 'FAMILY');
      this.source = familiesType.map(family => {
        return {
          id: family.id,
          kidsCount: family.kidsCount,
          kidsAges: family.kidsAges,
          kidsNames: family.kidsNames,
          creatorUserId: family.creatorUser.id,
          creatorUserEmail: family.creatorUser.email,
          creatorUserUsername: family.creatorUser.name,
          picture: family.creatorUser.picture,
          phone: family.creatorUser.phone,
          createdAt: family.creatorUser.createdAt,
          language: family.creatorUser.language,
          gender: family.creatorUser.gender,
          birthDate: family.creatorUser.birthDate,
          address: family.creatorUser.address,
          zipCode: family.creatorUser.zipCode,
          cityName: family.creatorUser.cityName,
          enabled: family.creatorUser.enableOauth,


        };

      });

      console.log('source', this.source);
      for (let i = 0; i < this.source.length; i++) {
        if (this.source[i].picture == null) {
          this.source[i].picture = 'assets/images/no-image.png';
        } else {
         this.source[i].picture = environment.apiUrl + this.source[i].picture;
        }
      }
      this.loadingSpinner = false;
    }
    , error => {
      this.loadingSpinner = false;
      if (error.status === 403) {
        localStorage.clear();
        this.router.navigate(['auth/login']);
      }

    });


  }

  onCustom(event: any) {
    console.log('custom event called');
    if (event.action === 'show') {
      this.openDialog(this.showDialog, event.data);
    }
  }

  openDialog(dialog: any, family: any) {
    const context: any = {
      family,
    };
    this.dialogService.open(dialog, { context });
  }

  onActionShowClick(family: any) {
    this.openDialog(this.showDialog, family);
  }

  onDelete(family: any): void {
    const creatorUserUsername = family.creatorUserUsername;
    this.dialogService.open(this.deleteDialogRef, {
      context: { id: family.id, creatorUserUsername },
    });
  }

  delete(id: number) {
    this.loadingSpinner = true;
    this.familiesService.delete(id).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('Supprimer', 'famille supprimé avec succés.', 'success');
        this.loadAllFamilies();
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Supprimer', 'Erreur survenue lors de suppression de famille.', 'danger');
      },
    );
  }


  showToast(title: string, message: string, status: any) {
    this.toastrService.show(message, title, { status });
  }

  onEdit(family: any) {
    console.log('id', family.id);
    this.dialogService.open(this.editDialogRef, {
      context: { id: family.id, family },
    });
  }

  edit(id: number, data: any) {
    console.log('data', data);

    const body = {
      name: data.family.creatorUserUsername,
      email: data.family.creatorUserEmail,
      phone: data.family.phone,
      address: data.family.address,
    };

    this.loadingSpinner = true;
    this.familiesService.edit(data.family.creatorUserId, body).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('Modification', 'famille modifiée avec succés.', 'success');
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Modification', 'Erreur survenue lors de modification .', 'danger');
      },
    );
  }

  onActionActivateClick(family: any): void {
    const email = family.creatorUserEmail;
    this.dialogService.open(this.activateDialogRef, {
      context: { id: family.id, email },
    });
  }

  activate(data: any) {
    console.log('data', data);

    this.loadingSpinner = true;
    this.familiesService.activateUser(data).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('Activation', 'Compte activé avec succés.', 'success');
        this.loadAllFamilies();
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Activation', 'Erreur survenue lors de l activation  de compte.', 'danger');
      },
    );
  }
}
