import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NanniesService, AssetsService } from '../../../services';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

declare var require: any;
const FileSaver = require('file-saver');
@Component({
  selector: 'app-list',
  templateUrl: './list-nannies.component.html',
  styleUrls: ['./list-nannies.component.scss'],
})

export class ListNanniesComponent implements OnInit {
  @ViewChild('showDialog', null) showDialog: TemplateRef<any>;
  @ViewChild('deleteDialog', null) deleteDialogRef: TemplateRef<any>;
  @ViewChild('editDialog', null) editDialogRef: TemplateRef<any>;
  @ViewChild('activateDialog', null) activateDialogRef: TemplateRef<any>;
  @ViewChild('publicDialog', null) publicDialogRef: TemplateRef<any>;
  @ViewChild('inactivateDialog', null) inactivateDialogRef: TemplateRef<any>;
  @ViewChild('privateDialog', null) privateDialogRef: TemplateRef<any>;
  @ViewChild('fileDialog', null) fileDialogRef: TemplateRef<any>;

  kind: string;
  gender: string;
  loadingSpinner = false;
  source: any;
  nannies: any;
  users: any;
  page = 1;
  pageSize = 10;
  enabled: string ;
  public: string;
  public files: any;
  public assets: any;

  constructor(
    private readonly dialogService: NbDialogService,
    private readonly nanniesService: NanniesService,
    private toastrService: NbToastrService,
    private readonly router: Router,
    private readonly assetsService: AssetsService,

  ) {
    this.loadAllNannies();
  }

  ngOnInit() {
  }

  loadAllNannies() {
    this.loadingSpinner = true;
    this.nanniesService.getNannies().subscribe((nannies: any) => {

      this.loadingSpinner = false;
      const nanniesType = nannies.filter(nannie => nannie.creatorUser.userType === 'NANNY');
      this.source = nanniesType.map(nanny => {
        return {
          id: nanny.id,
          kind: nanny.kind,
          creatorUserId: nanny.creatorUser.id,
          creatorUserEmail: nanny.creatorUser.email,
          creatorUserUsername: nanny.creatorUser.name,
          picture: nanny.creatorUser.picture,
          phone: nanny.creatorUser.phone,
          nationality: nanny.nationality,
          approvalNumber: nanny.approvalNumber,
          createdAt: nanny.creatorUser.createdAt,
          language: nanny.creatorUser.language,
          gender: nanny.creatorUser.gender,
          bio: nanny.bio,
          birthDate: nanny.creatorUser.birthDate,
          address: nanny.creatorUser.address,
          zipCode: nanny.creatorUser.zipCode,
          cityName: nanny.creatorUser.cityName,
          enabled: nanny.creatorUser.enableOauth,
          public: nanny.public,
        };
      });

      for (let i = 0; i < nanniesType.length; i++) {
        if (nanniesType[i].enabled === 'true') {
          this.enabled = 'Active';
          console.log('enbaled', this.enabled  );
        } else if (nanniesType[i].enabled === 'false') {
          this.enabled = 'Inactive';
        }
      }

      for (let i = 0; i < nanniesType.length; i++) {
        if (nanniesType[i].public === 'true') {
          this.public = 'Public';
          console.log('enbaled', this.public  );
        } else if (nanniesType[i].public === 'false') {
          this.public = 'Privé';
        }
      }

      for (let i = 0; i < nanniesType.length; i++) {
        if (nanniesType[i].kind === 'CERTIFIED') {
          this.kind = 'Agréée';
        } else if (nanniesType[i].kind === 'GRADUATED') {
          this.kind = 'Diplômée';
        }
      }

      for (let i = 0; i < this.source.length; i++) {
        if (this.source[i].picture == null) {
          this.source[i].picture = 'assets/images/users.svg';
        } else {
          this.source[i].picture = environment.apiUrl + this.source[i].picture;
        }
      }

      for (let i = 0; i < this.source.length; i++) {
        if (this.source[i].gender === 'Male') {
          this.gender = 'Homme';
        } else if (this.source[i].gender === 'Female') {
          this.gender = 'Femme';
        }
      }
    }
    , error => {
      this.loadingSpinner = false;
      if (error.status === 403) {
        localStorage.clear();
        this.router.navigate(['auth/login']);
      }

    });
  }

  onActionShowClick(nanny: any) {
    this.openDialog(this.showDialog, nanny);
  }

  onDelete(nanny: any): void {
    const creatorUserUsername = nanny.creatorUserUsername;
    this.dialogService.open(this.deleteDialogRef, {
      context: { id: nanny.id, creatorUserUsername },
    });
  }

  delete(id: number) {
    this.loadingSpinner = true;
    this.nanniesService.delete(id).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('Supprimer', 'Nanny supprimé avec succés.', 'success');
        this.loadAllNannies();
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Supprimer', 'Erreur survenue lors de suppression de nanny.', 'danger');
      },
    );
  }

  openDialog(dialog: any, nanny: any) {
    const context: any = {
      nanny,
    };
    this.dialogService.open(dialog, { context });
  }

  showToast(title: string, message: string, status: any) {
    this.toastrService.show(message, title, { status });
  }

  onEdit(nanny: any) {
    console.log('id', nanny.id);

    // this.openDialog(this.editDialogRef, nanny);
    this.dialogService.open(this.editDialogRef, {
      context: { id: nanny.id, nanny },
    });
  }

  edit(id: number, data: any) {
    console.log('data', data);

    const body = {
      name: data.nanny.creatorUserUsername,
      email: data.nanny.creatorUserEmail,
      phone: data.nanny.phone,
      address: data.nanny.address,
      zipCode: data.nanny.zipCode,
      cityName: data.nanny.cityName,

    };

    this.loadingSpinner = true;
    this.nanniesService.edit(data.nanny.creatorUserId, body).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('modification', 'Nanny modifié avec succés.', 'success');
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Supprimer', 'Erreur survenue lors de modification .', 'danger');
      },
    );
  }

  onActionActivateClick(nanny: any): void {
    const email = nanny.creatorUserEmail;
    this.dialogService.open(this.activateDialogRef, {
      context: { id: nanny.id, email },
    });
  }

  activate(data: any) {
    console.log('data', data);

    this.loadingSpinner = true;
    this.nanniesService.activateUser(data).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('Activation', 'Compte activé avec succés.', 'success');
        this.loadAllNannies();
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Activation', 'Erreur survenue lors de l activation  de compte.', 'danger');
      },
    );
  }

  inactivate(data: any) {
    console.log('data', data);

    this.loadingSpinner = true;
    this.nanniesService.inactivateUser(data).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('Désactivation', 'Désactivation du Compte avec succés.', 'success');
        this.loadAllNannies();
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Désactivation', 'Erreur survenue lors de l activation  de compte.', 'danger');
      },
    );
  }

  onActionBlockClick(nanny: any): void {
    const email = nanny.creatorUserEmail;
    this.dialogService.open(this.inactivateDialogRef, {
      context: { id: nanny.id, email },
    });
  }

  onActionPublishClick(nanny: any): void {
    const email = nanny.creatorUserEmail;
    this.dialogService.open(this.publicDialogRef, {
      context: { id: nanny.id, email },
    });
  }

  private(data: any) {
    console.log('data', data);

    this.loadingSpinner = true;
    this.nanniesService.publishUser(data).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('Publication', 'Compte publié avec succés.', 'success');
        this.loadAllNannies();
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Publication', 'Erreur survenue lors de la publication de compte.', 'danger');
      },
    );
  }

  onActioninPublishClick(nanny: any): void {
    const email = nanny.creatorUserEmail;
    this.dialogService.open(this.privateDialogRef, {
      context: { id: nanny.id, email },
    });
  }

  inprivate(data: any) {
    console.log('data', data);

    this.loadingSpinner = true;
    this.nanniesService.inpublishUser(data).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('Désactiver la Publication du compte', 'succés ', 'success');
        this.loadAllNannies();
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Désactiver la Publication du compte', 'Erreur survenue lors de la désactivation de compte.', 'danger');
      },
    );
  }

/* getAssets() {
    this.assetsService.getAssets({ nanny: this.nannyId }).$observable.subscribe((assets) => {
        console.log('getAssets - assets: ' + JSON.stringify(assets));
        const AssetsTypeImage = assets.filter(asset => (asset.fileType === 'image/jpeg'));
        assets = AssetsTypeImage;
        this.assets = assets;
        console.log('assets', this.assets);
      });
    }*/

   /* getFiles() {
      this.assetsService.getAssets().subscribe((files) => {
        console.log('getFiles - files: ' + JSON.stringify(files));
        this.files = files;
        console.log('files', this.files);
      });
    }*/

    ShowFileClick(nanny: any) {
      this.openDialog(this.fileDialogRef, nanny);
      console.log('id', nanny.creatorUserId);
     // console.log('nanny', nanny);
      this.assetsService.getAssets(nanny.creatorUserId).subscribe((files) => {
        console.log('getFiles - files: ' + JSON.stringify(files));
        this.files = files;
        for (let i = 0; i < this.files.length; i++) {
         /* if (this.files[i].textValue != null) {
            this.files[i].textValue = environment.apiUrl + this.files[i].textValue;
          }*/
        }
        console.log('files', this.files);
      });
    }

    downloadPdf(pdfUrl: string, filePath: string ) {
      for (let i = 0; i < this.files.length; i++) {
      FileSaver.saveAs(environment.apiUrl + this.files[i].filePath);
      }
    }

    openDoc(pdfUrl: string , filePath: string) {
      window.open(environment.apiUrl + '/uploads/users/c5/000013/4c03fed2afd3ab44f15a4bb0382948bd.pdf');
      }

}
