import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MainComponent } from '../pages/main/main.component';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { LoginComponent } from '../pages/auth/login/login.component';
import { ListNanniesComponent } from '../pages/nannies/list-nannies/list-nannies.component';
import { ListFamiliesComponent } from '../pages/families/list-families/list-families.component';
import { ListEnterprisesComponent } from '../pages/enterprises/list-enterprises/list-enterprises.component';
import { ListReservationsComponent } from '../pages/reservations/list-reservations/list-reservations.component';
import { ListPaymentsComponent } from '../pages/payments/list-payments/list-payments.component';
import { RequestPasswordComponent } from '../pages/auth/request-password/request-password.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { AuthGuard } from '../guards/auth.guard';
import { ShowReservationComponent } from '../pages/reservations/show-reservation/show-reservation.component';
import { SubscriptionsComponent } from '../pages/subscriptions/subscriptions.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [

      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'list-enterprises',
        component: ListEnterprisesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'list-families',
        component: ListFamiliesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'list-nannies',
        component: ListNanniesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'list-reservations',
        component: ListReservationsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'list-payments',
        component: ListPaymentsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'subscriptions',
        component: SubscriptionsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'auth/login', component: LoginComponent,
  },
  {
    path: 'reservations/show-reservation/:id',
    component: ShowReservationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'request-password',
    component: RequestPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: '**', component: NotFoundComponent,
  },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
