import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ReservationsService } from '../../../services';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'list-reservations',
  templateUrl: './list-reservations.component.html',
  styleUrls: ['./list-reservations.component.scss'],
})
export class ListReservationsComponent implements OnInit {
  @ViewChild('showDialog', null) showDialog: TemplateRef<any>;
  @ViewChild('deleteDialog', null) deleteDialogRef: TemplateRef<any>;
  @ViewChild('editDialog', null) editDialogRef: TemplateRef<any>;

  public gender: string;
  public loadingSpinner = false;
  public source: any;
  public reservations: any;
  public page = 1;
  public pageSize = 10;
  public status: string ;
  public selectedId: any;
  constructor(
    private readonly dialogService: NbDialogService,
    private readonly reservationsService: ReservationsService,
    private toastrService: NbToastrService,
    private readonly router: Router,

  ) {
    this.loadAllReservations();
  }

  ngOnInit() {
  }

  loadAllReservations() {
    this.loadingSpinner = true;
    this.reservationsService.getReservations().subscribe((reservations: any) => {
    this.reservations = reservations ;
    console.log('this.reservations', this.reservations);

      this.loadingSpinner = false;

      for (let i = 0; i < this.reservations.length; i++) {
        if (this.reservations[i].status === 'FAMILY_CANCEL') {
          this.status = 'Famille a annulée la réservation';
        } else if (this.reservations[i].status === 'FAMILY_DELETE') {
          this.status = 'Famille a supprimée la réservation';
        } else if (this.reservations[i].status === 'FAMILY_REQUEST') {
          this.status = 'Nannie N\'a pas encore répondu';
        } else if (this.reservations[i].status === 'NANNY_ACCEPTED') {
          this.status = 'Nannie a acceptée';
        } else if (this.reservations[i].status === 'NANNY_CANCEL') {
          this.status = 'Nannie a annulée la réservation';
        } else if (this.reservations[i].status === 'NANNY_REFUSED') {
          this.status = 'Nannie a refusée';
        } else if (this.reservations[i].status === 'PAYMENT_SUCCESS') {
          this.status = 'Paiement réussi';
        } else if (this.reservations[i].status === 'PAYMENT_NANNY_SUCCESS ') {
          this.status = 'Paiement réussi';
        }
      }


    }
    , error => {
      this.loadingSpinner = false;
      if (error.status === 403) {
        localStorage.clear();
        this.router.navigate(['auth/login']);
      }

    });
  }

  onDelete(reservation: any): void {
    const creatorUser = reservation.family.name;
    this.dialogService.open(this.deleteDialogRef, {
      context: { id: reservation.id, creatorUser },
    });
  }

  delete(id: number) {
    this.loadingSpinner = true;
    this.reservationsService.delete(id).subscribe(
      () => {
        this.loadingSpinner = false;
        this.showToast('Supprimer', 'réservation supprimé avec succés.', 'success');
        this.loadAllReservations();
      },
      error => {
        this.loadingSpinner = false;
        this.showToast('Supprimer', 'Erreur survenue lors de suppression de réservation.', 'danger');
      },
    );
  }

  openDialog(dialog: any, reservation: any) {
    const context: any = {
      reservation,
    };
    this.dialogService.open(dialog, { context });
  }

  showToast(title: string, message: string, status: any) {
    this.toastrService.show(message, title, { status });
  }

  onCustom(reservation: any) {
    this.selectedId = reservation.id;
    console.log('id', this.selectedId);
    this.router.navigate(['/reservations/show-reservation/', reservation.id]);
  }

}
