import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { NbToastrService, NbGlobalLogicalPosition } from '@nebular/theme';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss'],
})
export class RequestPasswordComponent implements OnInit {
  isSubmitted = false;

  loadingSpinner = false;
  submitted = false;
  requestpasswordForm: FormGroup;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly toastrService: NbToastrService,
  ) {
    this.requestpasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  ngOnInit() { }

  onResetPasswordClick() {

    this.isSubmitted = true;
    if (this.requestpasswordForm.invalid) {
      return;
    }
    this.loadingSpinner = true;
    this.authService.restPassword(this.requestpasswordForm.value)
      .subscribe(res => {
        this.loadingSpinner = false;
        this.showSuccessToast();

        console.log('res', res);
        this.router.navigate(['login']);
      },
        error => {
          this.loadingSpinner = false;
          this.showFailToast();

        },
      );
  }

  showSuccessToast() {
    this.toastrService.success(
      'Your reset password password request has been submitted, please check your email.',
      'Request submitted',
      { position: NbGlobalLogicalPosition.TOP_END, duration: 0 });
  }

  showFailToast() {
    this.toastrService.danger(
      'The given email not found.',
      'Error',
      { position: NbGlobalLogicalPosition.TOP_END, duration: 0 });
  }
  backLogin() {
    this.router.navigate(['/auth/login']);
  }
}
