import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isSubmitted = false;
  isInvalidCredentials = false;
  loadingSpinner = false;
  loginForm: FormGroup;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
  }

  ngOnInit() {
  }

  loginClick() {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loadingSpinner = true;
    this.authService.login(this.loginForm.value)
      .subscribe(res => {
        this.loadingSpinner = false;
        console.log('res', res);
        localStorage.setItem('user', JSON.stringify(res));
        localStorage.setItem('token', res.token);
        this.router.navigate(['dashboard']);
      },
        error => {
          this.loadingSpinner = false;
          if (error.status === 401) {
            this.isInvalidCredentials = true;
          }
        },
      );
  }

}
